import _asyncToGenerator from "@babel/runtime/helpers/esm/asyncToGenerator";
import { useApi } from '~/plugins/api';
import Keycloak from './keycloak.js';
export function useKeycloak(_ref) {
  var {
    setUserToken,
    tokenSSO
  } = _ref;
  var app = useNuxtApp().nuxt2Context.app;
  var {
    router
  } = app;
  var authApi = useApi().auth;
  var ssoLoading = ref(false);
  var config = ref();
  var sso = ref(false);
  function getConfig() {
    return _getConfig.apply(this, arguments);
  }
  function _getConfig() {
    _getConfig = _asyncToGenerator(function* () {
      if (!config.value) {
        var authConfig = yield authApi.config();
        config.value = {
          oidcProvider: authConfig.realm_url,
          clientId: authConfig.web_client_id
        };
        sso.value = authConfig.sso;
      }
      return config.value;
    });
    return _getConfig.apply(this, arguments);
  }
  var keycloak;
  getConfig().then(() => {
    if (!sso.value) return;
    keycloak = new Keycloak(config.value);
    keycloak.init({
      checkLoginIframe: false
    });
    keycloak.onReady = authenticated => {
      ssoLoading.value = true;
      try {
        if (authenticated) {
          if (tokenSSO.value !== keycloak.token) {
            setUserToken(keycloak.token);
          }
          router.push('/');
        }
      } catch (e) {
        //
      } finally {
        ssoLoading.value = false;
      }
    };
  });
  function authSSO() {
    var _keycloak;
    (_keycloak = keycloak) === null || _keycloak === void 0 ? void 0 : _keycloak.login();
  }
  function authLogoutSSO() {
    var _keycloak2;
    return (_keycloak2 = keycloak) === null || _keycloak2 === void 0 ? void 0 : _keycloak2.logout();
  }
  function updateTokenSSO(_x) {
    return _updateTokenSSO.apply(this, arguments);
  }
  function _updateTokenSSO() {
    _updateTokenSSO = _asyncToGenerator(function* (minValidity) {
      try {
        var _keycloak3;
        var refreshed = yield (_keycloak3 = keycloak) === null || _keycloak3 === void 0 ? void 0 : _keycloak3.updateToken(minValidity);
        if (refreshed) {
          setUserToken(keycloak.token);
        }
      } catch (e) {
        console.error(e);
      }
    });
    return _updateTokenSSO.apply(this, arguments);
  }
  return {
    sso,
    authSSO,
    ssoLoading,
    authLogoutSSO,
    updateTokenSSO
  };
}